import React from 'react'
import { navigateTo } from 'gatsby'
import axios from 'axios'
import Layout from '../components/layout'

class ResetPassword extends React.Component {
    state = {
        loading: false,
        token: ``,
        email: ``,
        password: ``,
        password_confirmation: ``,
        showPassword: false,
        showConfirmPassword: false,
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search)
        const token = urlParams.get('token')
        this.setState({ token })
    }
    
    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit(event) {
        event.preventDefault()
        console.log(this.state)
        for (let key in this.state) {
            if (key === 'loading' || key == 'showPassword' || key == 'showConfirmPassword') continue
            if(!this.state[key] || this.state[key] == '') {
                alert('Please complete all fields!')
                return;
            }
        }
        this.setState({loading: true})

        // validate email
        axios.post(`${process.env.API_BASE_URL}/api/update-password`, {
                token: this.state.token,
                email: this.state.email,
                password: this.state.password,
                password_confirmation: this.state.password_confirmation
            })
            .then(() => {
                this.setState({loading: false})
                alert('You may now use your new password.')
                navigateTo('/login')
            })
            .catch(err =>{
                this.setState({loading: false})
                if (err.response) {
                    const errors = err.response.data.errors
                    for (let key in errors) {
                        alert(errors[key][0]);
                        break;
                    }
                }
            })
        
    }
    
    handleToggleShowPassword = () => {
      this.setState(state => ({
        showPassword: !state.showPassword,
      }))
    }
    
    handleToggleShowConfirmPassword = () => {
      this.setState(state => ({
        showConfirmPassword: !state.showConfirmPassword,
      }))
    }

    render() {
      const { showPassword, showConfirmPassword } = this.state
        return (
            <Layout>
                <h2 className="has-text-centered title is-3">Password Reset</h2>
                <form method="post" onSubmit={e => this.handleSubmit(e)}>
                    <div className="form-wrapper" style={{maxWidth:'450px',width:'80%'}}>
                        <div className="field">
                            <div className="control">
                                <label>Email*</label>
                                <input className="input" name="email" type="text" onChange={e => this.handleChange(e)} />
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <label>Password*</label>
                                  <label onClick={() => this.handleToggleShowPassword()}>
                                    {showPassword ? 'Hide' : 'Show'}
                                  </label>
                                </div>
                                <input className="input" name="password" type={showPassword ? 'text' : 'password'} onChange={e => this.handleChange(e)} />
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <label>Confirm Password*</label>
                                  <label onClick={() => this.handleToggleShowConfirmPassword()}>
                                    {showConfirmPassword ? 'Hide' : 'Show'}
                                  </label>
                                </div>
                                <input className="input" name="password_confirmation" type={showConfirmPassword ? 'text' : 'password'} onChange={e => this.handleChange(e)} />
                            </div>
                        </div>
                        <div className="fd-center">
                            <button type="submit" className={`button is-medium has-background-link has-text-white is-hidden-mobile ${this.state.loading ? 'is-loading' : ''}`} >Reset Password</button>
                            <div className="fd-central"><button type="submit" className={`button is-medium has-background-link has-text-white is-hidden-tablet is-fullwidth ${this.state.loading ? 'is-loading' : ''}`} >Reset Password</button></div>
                        </div>
                    </div>
                </form>
            </Layout>
        )
    }
}

export default ResetPassword